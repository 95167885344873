<template>
  <loading v-if="isLoading" />
  <div class="contries-list" v-else>
    <b-card class="m-0 p-1">
      <h3 class="head-tiltle">{{ $t("g.contryList/page_head") }}</h3>
      <vue-good-table
        class="w-100 m-2"
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table condensed custom-table "
        :sort-options="{
          enabled: false,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :row-style-class="rowStyleClassFn"
      >
        <template slot="table-column" slot-scope="props">
          <span
            class="text-nowrap d-flex justify-content-start px-1 column-text"
          >
            {{ $t(`g.${props.column.label}`) }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.label === 'countryList/table/country_name'"
            class="text-nowrap d-flex justify-content-start p-1 column-text"
          >
            <b-link
              :to="{
                name: 'show-country',
                params: {
                  id: props.row.id,
                  countryName: props.row.name,
                },
              }"
            >
              {{ props.row.name }}
            </b-link>
          </span>
          <span
            v-else-if="props.column.label === 'countryList/table/country_flag'"
            class="text-nowrap d-flex justify-content-start p-1"
          >
            <b-avatar size="2rem">
              <flag
                :iso="returnCountryFlag(props.row.currency)"
                class="w-100 h-100"
              />
            </b-avatar>
          </span>
          <span
            v-else-if="
              props.column.label === 'countryList/table/country_available'
            "
            class="text-nowrap d-flex justify-content-start p-1"
          >
            <b-form-checkbox
              :disabled="
                globalPermission.countries && !globalPermission.countries.update
              "
              v-model="props.row.is_available"
              @change="updateCountry(props.row.id, props.row.is_available)"
              button-variant="success"
              class="h-50"
              switch
            />
          </span>
          <span v-else class="text-nowrap d-flex justify-content-start p-1">
            {{ props.row.code }}
          </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['30', '50', '70']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>

            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BLink,
  BFormCheckbox,
  BAvatar,
  BFormInput,
  BPagination,
  BFormSelect,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import loading from "@/components/shared/loading/loading.vue";
import { countryCodes } from "@/assets/countriesData.js";
export default {
  name: "CountriesList",
  components: {
    BCard,
    BRow,
    VueGoodTable,
    loading,
    BLink,
    BFormCheckbox,
    BAvatar,
    BFormInput,
    BPagination,
    BFormSelect,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      isLoading: false,
      rows: [],
      columns: [
        {
          label: "countryList/table/country_name",
          field: "name",
        },
        {
          label: "countryList/table/country_code",
          field: "code",
          sortable: false,
        },
        {
          label: "countryList/table/country_flag",
          field: "flag",
          sortable: false,
        },
        {
          label: "countryList/table/country_available",
          field: "is_available",
          sortable: false,
        },
      ],
      searchTerm: null,
      pageLength: 20,
    };
  },
  methods: {
    returnCountryFlag(flag) {
      const selectedCountry = countryCodes.filter(
        (country) => country.code === flag
      )[0];
      return selectedCountry.countryCode;
    },
    async getData() {
      this.isLoading = true;
      try {
        const data = await this.$http.get("admin/countries");
        this.rows = data.data.data;
        this.isLoading = false;
      } catch (err) {
        this.$helpers.handleError(err);
        this.isLoading = true;
      }
    },
    async updateCountry(id, current) {
      try {
        // handeling data
        let formData = new FormData();
        formData.append("is_available", current ? 1 : 0);
        const res = await this.$http.post(
          `admin/countries/${id}?_method=put`,
          formData
        );
        // this.getData();
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    rowStyleClassFn(row) {
      const objectIndex = this.rows.findIndex((ele) => ele.id === row.id) + 1;
      return objectIndex % 2 == 0 ? "table-row-even" : "table-row-odd";
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
